import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';
import { DatabaseService } from '../_services/DatabaseService';

@Component({
  selector: 'app-complaint-report',
  templateUrl: './complaint-report.component.html'
})

export class ComplaintReportComponent implements OnInit {
  
  loading:boolean = false;

  data:any={};
  filter:any = {};
  date:any;
  
  constructor(public db:DatabaseService,  @Inject(MAT_DIALOG_DATA) public model_data: any, public dialog: DialogComponent, public dialogRef: MatDialogRef<ComplaintReportComponent>) {}
  
  ngOnInit() {
    this.date = new Date();
  }
  
  
  
  submit()
  {
    this.loading = true; 
    this.filter.date_from = this.data.date_from  ? this.db.pickerFormat(this.data.date_from) : '';
    this.filter.date_to = this.data.date_to  ? this.db.pickerFormat(this.data.date_to) : '';
    this.db.post_rqst(  {'filter': this.filter, 'login': this.db.datauser, 'type': this.model_data.complaintType}, 'karigar/complaint_report')
    .subscribe( d => {
      // if(d.status == 'data_not_found'){
      //   this.dialog.error( 'Report not generated');
      //   return;
      // }
      // else{
        document.location.href = this.db.myurl + '/app/uploads/exports/Complaint_report.csv';
        this.loading = false; 
        this.dialogRef.close(true);
      // }
    });
  }
  
}