import { Component, OnInit } from '@angular/core';
import { DatabaseService } from '../../_services/DatabaseService';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../../dialog/dialog.component';
import { SessionStorage } from '../../_services/SessionService';
import { ProductImageModuleComponent } from '../product-image-module/product-image-module.component';
import { MatDialog } from '@angular/material';
import * as _ from 'lodash';
import { CategoryModelComponent } from '../category-model/category-model.component';

@Component({
    selector: 'app-main-category',
    templateUrl: './main-category.component.html',
})
export class MainCategoryComponent implements OnInit {

    // toggle = false;
    savingData = false;
    category: any = {};
    loading_list = false;
    total_categories: any = 0;
    categories: any = [];
    sub_categories: any = [];
    last_page: number;
    current_page = 1;
    search: any = '';
    source: any = '';
    searchData = true;
    isInvoiceDataExist = false;
    filter: any = {};
    filtering: any = false;
    toggle: any;
    toggle1: any;
    selected_image: any = [];
    save_button_disabled: boolean = false;
    Url:any='';
    catFlag:boolean= false;
    
    constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, public ses: SessionStorage, public dialog: DialogComponent, public alrt: MatDialog) {
        this.Url = this.db.categoryImg;
     }

    ngOnInit() {
        this.getCategoryList();
        this.getSubCategoryList();
        this.category.image = [];
    }


    redirect_previous() {
        this.current_page--;
        this.getCategoryList();
        this.getSubCategoryList();

    }

    redirect_next() {
        if (this.current_page < this.last_page) { this.current_page++; }
        else { this.current_page = 1; }
        this.getCategoryList();
        this.getSubCategoryList();

    }

    editCategory(main_category,actual_image_name) {        
        this.image = [];
        this.category = this.categories.filter(x => x.main_category == main_category)[0];
        this.category.image= actual_image_name;
    }

    savecategory(form: any) {
        console.log(this.category.id);
        this.savingData = true;
        this.save_button_disabled = true;
        this.loading_list = true;

        this.db.post_rqst({ 'category': this.category }, 'master/addMainCategorynew')
            .subscribe(d => {
                this.savingData = false;
                console.log(d);
                this.catFlag = false;
                this.toggle = "false"
                console.log(this.selected_image);
                this.loading_list = false;

                this.save_button_disabled = false;
                this.router.navigate(['main-category-list']);
                this.dialog.success(' successfully save');
                this.getCategoryList();
            });
    }

    addCategory() {
        this.category = {};
        //console.log("dscds");

    }

    catdata: any = '';


    getData: any = {};
    getCategoryList() {
        //console.log(this.db.datauser);
        this.loading_list = true;

        if (this.filter.date || this.filter.location_id) this.filtering = true;
        this.db.post_rqst({ 'filter': this.filter }, 'master/mainCategoryForProduct')
            .subscribe(d => {
                //console.log(d);

                //console.log(this.loading_list);


                this.categories = d.category;
                // for (let i = 0; i < this.categories.length; i++) {

                //     this.category.image = this.categories[i].actual_image_name;
        
                //          console.log( this.category.image );
                // }

                this.loading_list = false;

            });
    }


    onUploadChange1(evt: any) {
        console.log(evt);
        const file = evt.target.files[0];
        if (file.size > 2028812) {
            this.dialog.error('Image size more than 2 Mb is not allowed.');
            return;
        }
        this.catFlag = true;
        if (file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded1.bind(this);
            reader.readAsBinaryString(file);
            console.log(reader);
            console.log('in if')
        }

    }




    onUploadChange(evt: any) {
        console.log(evt);
        const file = evt.target.files[0];
        if (file.size > 2028812) {
            this.dialog.error('Image size more than 2 Mb is not allowed.');
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = this.handleReaderLoaded1.bind(this);
            reader.readAsBinaryString(file);
            console.log(reader);
            console.log('in if')
        }
    }
    handleReaderLoaded1(e) {
        this.category.image = 'data:image/png;base64,' + btoa(e.target.result);
        console.log(this.category.image)

    }

    deleteProduct(main_category) {
        this.loading_list = true;
        this.dialog.delete('Category').then((result) => {
            if (result) {
                this.db.post_rqst({ main_category: main_category }, 'master/addMainCategoryImageDelete')
                    .subscribe(d => {
                        console.log(d);
                        this.getCategoryList();
                        this.dialog.successfully();
                    });
            }
        });
    }
    image: any = []

    deleteProductImage(index) {
        this.category.image.splice(index, 1)
    }


    active: any = '';
    ProductProfile(index) {
        this.active = index;
        this.category.profile_selected = index;
    }
    openDialog(id, string) {

        const dialogRef = this.alrt.open(ProductImageModuleComponent, {
            width: '1024px',
            data: {
                'id': id,
                'mode': string,
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log(`Dialog result: ${result}`);
        });

    }

    exportMainCategory() {
        this.filter.mode = 1;
        this.db.post_rqst({ 'filter': this.filter, 'login': this.db.datauser }, 'master/exportMainCategory')
            .subscribe(d => {
                document.location.href = this.db.myurl + '/app/uploads/exports/MainCategory.csv';
                //console.log(d);
            });
    }


    getSubCategoryList() {
        //console.log(this.db.datauser);

        if (this.filter.date || this.filter.location_id) this.filtering = true;
        this.db.post_rqst({ 'filter': this.filter }, 'master/categoryList')
            .subscribe(d => {

                console.log("master/categoryList Response ", d);

                //console.log(this.loading_list);


                this.sub_categories = d.categories;
                this.selected_image = d.categories.image;
                console.log(this.categories);

            });
    }


    addSubCategory(main_category) {
        this.category = {};
        this.category.main_category = main_category;
        this.image = [];

    }
    id: any = {}
    editSubCategory(id,technical_specification) {
console.log(technical_specification);
        
        this.EDITIMAGE(id,technical_specification)
        console.log(this.sub_categories, id);
        this.id = id
        this.category.image = [];


        this.category = this.sub_categories.filter(x => x.id == id)[0];
        console.log(this.category);
        this.category.profile_selected = 0;

        this.selected_image = [];
        for (let i = 0; i < this.image.length; i++) {

            if (parseInt(this.image[i].profile) == 1) {

                this.category.profile_selected = i;
            }
            // this.category.image.push(this.image[i].image);

            console.log("selected image in loop", this.selected_image);
        }

        console.log("selected image out of loop", this.selected_image);


    }
    EDITIMAGE(id,technical_specification) {
        console.log(this.id);
        this.delete = ''
        this.loading_list = true;
        this.db.post_rqst({ 'id': id }, 'master/subCategoryList').subscribe(d => {
            this.loading_list = false;
            this.image = d.image;

            for (let i = 0; i < this.image.length; i++) {
                if (parseInt(this.image[i].profile) == 1) {
                    this.category.profile_selected = i;
                }
                this.category.image = this.image[i].image;
                this.category.technical_specification = technical_specification;
                console.log(this.category.technical_specification);
                
            }
        });
    }


    // editimage(){
    //     this.db.post_rqst({ 'filter': this.filter }, 'master/categoryList')
    //             .subscribe(d => {

    //                 console.log("master/categoryList Response ",d);

    //                 this.loading_list = false;
    //                 //console.log(this.loading_list);


    //                 this.sub_categories = d.categories;
    //                 this.selected_image = d.categories.image;
    //                 console.log(this.categories);

    //             });
    // }

    file: any = {};
    file_name: any;
    imageError: any = '';
    typecheck: any = '';
    istrue: boolean = false;
    onUploadChangepdf(evt: any) {
        //   this.imageError = null;
        console.log(evt);
        this.file = evt.target.files[0];
        console.log(this.file);
        this.file_name = this.file.name;
        const allowed_types = ['application/pdf'];
        this.typecheck = !_.includes("application/pdf", this.file.type)
        if (!_.includes(allowed_types, this.file.type)) {
            this.dialog.error('Only Pdf File Accepted');
            this.file_name = ''
            this.istrue = false;
            return
        }
        if (this.file.size >= 10000000) {
            this.dialog.error('PDF file size is too large, maximum file size is 10 MB.');
            this.file_name = ''
            this.istrue = false;
            return
        }
        else { this.istrue = true; }
    }


    formData = new FormData();
    saveSubcategory(form: any) {
        console.log(this.category.image);
        this.savingData = true;
        this.loading_list = true;
        this.save_button_disabled = true;
        if (this.category.id) {
            this.category.edit_cat_id = this.category.id;
        }
        this.category.created_by = this.db.datauser.id;

        // this.category.image = this.selected_image ? this.selected_image : [];
        // console.log(this.selected_image);
        console.log(this.category.image);
        // this.formData.append('pdf', this.file,this.file_name);
        // this.formData.append('category', this.category.main_category);
        // this.formData.append('created_by', this.db.datauser.id);

        // this.db.insert_rqst(this.formData, 'master/addCategory')
        this.db.insert_rqst({ 'category': this.category, 'created_by': this.db.datauser.id }, 'master/addCategory')
            .subscribe((d) => {
                this.savingData = false;
                //console.log( d );
                if (d['status'] == 'EXIST') {
                    this.save_button_disabled = false;
                    this.dialog.error('This Category Already exists');
                    this.loading_list = false;

                    return;
                }

                if (d['status'] == 'SUCCESS') {
                    if (d['id'] && this.file_name != undefined) {
                        console.log('In side conditon');

                        this.formData.append('pdf', this.file, this.file_name);
                        this.formData.append('id', d['id'])
                        this.db.fileData(this.formData, 'category_pdf ').subscribe(r => {
                            console.log(r)
                        });

                    }

                    console.log('Out side conditon');

                    this.toggle1 = "false";

                    this.selected_image = [];
                    //console.log(this.selected_image);
                    this.save_button_disabled = false;
                    this.router.navigate(['main-category-list']);
                    this.dialog.success('Category successfully save');
                    this.getCategoryList();
                    this.delete = ''
                    this.getSubCategoryList();

                }

            });
    }

    deleteSubCategory(id) {
        this.dialog.delete('Sub Category').then((result) => {
            if (result) {
                this.db.post_rqst({ category_id: id }, 'master/categoryDelete')
                    .subscribe(d => {
                        console.log(d);
                        this.getCategoryList();
                        this.dialog.successfully();
                    });
            }
        });
    }

    clearData() {
        //console.log('close');
        this.selected_image = [];
    }
    delete: any
    deleteSubCategoryImage(index) {
        console.log(index);

        this.image.splice(index, 1)
        console.log();
        this.delete = "test"
        console.log(this.delete);

    }


}